// //////////////////////////////////////// 
// BLOCKS
// //////////////////////////////////////// 
.display-block {
	display: block;
}

.block {
	display: flex;
	position: relative;

	padding: var(--medium);

	background: white;
	border-radius: var(--thin);

	box-shadow: 0px 4px 16px -8px rgba(var(--dark),0.1);

	transition: 0.2s;
}