
.page-header {
	display: flex;
	position: absolute;

	flex-wrap: wrap;

	width: 100%;
	height: auto;

	opacity: 0;

	background: transparent;

	padding: 2rem;

	justify-content: space-between;
	align-items: center;
	
	 transform: translateY(-160px);
	
	.header-logo svg { height: 3rem; width: 3rem; } 
	.lines-intro { opacity: 0; } 
}

header.scrolled {
	position: fixed !important; 
	background: white;

	.white { fill: white;}
	.main { fill: #00ff88; } 

	.lines-intro-header {
		opacity: 1;
	}
} 

@media screen and (max-width: 1024px) {
	.page-header {
		padding: 2rem;

		.header-logo {
			svg { height: 2.5rem; width: 2.5rem; } 
		}

		.lines-intro {
			opacity: 1;
		}
	}

	#headerNav {
		a { font-size: 1.375rem; }
	}
}

@media screen and (max-width: 321px) {
	.page-header {
		#headerNav {
			a { font-size: 1.75rem; }
		}
	}
}
