.item {
	display: flex;
	position: relative;

	overflow: hidden; 

	flex-direction: row;

	justify-content: flex-start;
	align-items: center;

	padding: var(--medium);

	border: 1px solid rgba(0,0,0,0.1); 

	background: white;
	border-radius: var(--thin);

	// box-shadow: 0px 8px 8px -8px rgba(var(--dark),0.1);

	transition: 0.2s;
}
