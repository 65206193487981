@charset "UTF-8";
html, button, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, textarea, input {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; 
}

a:link, a:visited, a:hover {
  color: inherit;
  text-decoration: none;
}

li {
    list-style: none;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

header, select, input, span, label, textarea {
  box-sizing: border-box; 
  font-family: inherit; 
}

input, textarea  {
  &, &:link, &:focus, &:visited, &:active {
    text-decoration: none; 
    outline: none;
    border: none;
    -moz-appearance:none;
    -webkit-appearance:none;
    -o-appearance:none;
  }
}

// Resets for inputs
a, input, textarea {
  background: transparent;
  text-decoration: none; 
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}