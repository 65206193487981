@font-face {font-family: 'Avenir Next'; src: url("@/assets/fonts/AvenirNext-Regular.woff2") 	format("woff2"); font-weight: 400; font-style: normal; }
@font-face {font-family: 'Avenir Next'; src: url("@/assets/fonts/AvenirNext-Medium.woff2")  	format("woff2"); font-weight: 500; font-style: normal; }
@font-face {font-family: 'Avenir Next'; src: url("@/assets/fonts/AvenirNext-DemiBold.woff2")	format("woff2"); font-weight: 600; font-style: normal; }
@font-face {font-family: 'Avenir Next'; src: url("@/assets/fonts/AvenirNext-Bold.woff2")    	format("woff2"); font-weight: 700; font-style: normal; }
@font-face {font-family: 'Avenir Next'; src: url("@/assets/fonts/AvenirNext-Heavy.woff2")    format("woff2"); font-weight: 800; font-style: normal; }

// Headers
.tx-extra   { padding: var(--extra);  }
.tx-big   	{ padding: var(--big);  	}
.tx-semi  	{ padding: var(--semi);   } 
.tx-medium  { padding: var(--medium); }
.tx-regular { padding: var(--regular);}
.tx-small 	{ padding: var(--small);  } 
.tx-thin  	{ padding: var(--thin);   } 
.tx-zero  	{ padding: 0;   		} 
// Font Styles
h1,.h1  { font-weight: 500; font-size: 4.500rem; 	line-height: 1.125; letter-spacing: -0.1rem; 	text-transform: none;				}
h2,.h2  { font-weight: 500; font-size: 3.000rem; 	line-height: 1.125; letter-spacing: -0.0rem;	text-transform: none;				}
h3,.h3  { font-weight: 500; font-size: 2.000rem; 	line-height: 1.125; letter-spacing: -0.0rem;	text-transform: none;				}
h4,.h4  { font-weight: 700; font-size: 1.325rem; 	line-height: 1.125; letter-spacing: +0.0rem; 	text-transform: uppercase;	}
h5,.h5 	{ font-weight: 600; font-size: 1.000rem; 	line-height: 1.125;	letter-spacing: -0.0rem; 	text-transform: none;				}
// Typogragy Styles
		.p-small     	{ font-weight: 400;				font-size: 0.75rem;		line-height: 1.500; }
p, 	.p-medium     { font-weight: 400;				font-size: 1.00rem;		line-height: 1.500	}
		.p-big     		{ font-weight: 400; 			font-size: 1.50rem;		line-height: 1.500;	letter-spacing: +0.0rem;	}

b  { font-weight: 700}

a  				{	transition: all 0.233s ease; }
a:hover   { cursor: pointer; color: #00ff88;} 
a:active  { opacity: 0.75 }
// Text align
.t-left   	{ text-align: left; 	}
.t-center   { text-align: center; }
.t-right  	{ text-align: right; 	}
// Linehegiht
.t-lh-1 		{ line-height: 1}
.t-lh-15 		{ line-height: 1.5}
.t-lh-2 		{ line-height: 2}
// Font weights
.t-bold   	{ font-weight: 900;}
.t-demi   	{ font-weight: 700;}
.t-semi   	{ font-weight: 600;}
.t-medium 	{ font-weight: 500;}
.t-regular 	{ font-weight: 300;}
.t-light  	{ font-weight: 200;}
// Line height
.lh-bold   	{ line-height: 3.000; }
.lh-demi   	{ line-height: 2.000; }
.lh-semi   	{ line-height: 1.500; }
.lh-medium 	{ line-height: 1.250; }
.lh-regular { line-height: 1.125; }
.lh-light  	{ line-height: 1.000; }
// Text decoration
.uppercase 				{ text-transform: uppercase;			}
.capitalize 				{ text-transform: capitalize;			}
.underline 				{ text-decoration: underline; 		}
.line-through 		{ text-decoration: line-through; }