.breadcrumbs { 
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;


	height: 4rem;

	// border-bottom: 1px solid rgba(0,0,0,0.1);

	display: flex;
	align-items: center;

	flex-wrap: nowrap;

	.home {
		height: 1rem;

		path {
			fill: black; 
		}
	}

	.chevron {
		height: 0.75rem;


		path {
			fill: black; 
		}
	}

	a {
		display: flex;
		align-items: center;

		flex-wrap: nowrap;

		color: black; 

		span {
			margin-left: 0.5rem;
			margin-right: 0.5rem;
		}
	}

	.router-link-exact-active:last-of-type {
		span {
			color: #00ff88;
		} 
	}
}