

*,*:hover  {cursor: none !important; }


.button-play {
  pointer-events: none;
  background: #00ff88;
  width: 8rem;
  height: 8rem;
   top: 50%;
   left: 50%;
  border-radius: 20rem;
  position: fixed;
  z-index: 25;
  opacity: 0;
  transform: translate(-50%, -50%);

    transition: all 0.33s cubic-bezier(.17,.67,.41,1.01);

      svg { height: 6rem; width: 6rem; pointer-events: none;}
}


.cursor {
  position: fixed;
  background-color: black;
   border:1px solid white;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  z-index: 1;
  transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
  0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
  user-select: none;
  pointer-events: none;
  z-index: 10000;

  left: 50%;
  top: 50%;
  opacity: 0;
  transform: scale(0);
  visibility: hidden;

  &.visible {
    opacity: 1;
    transform: scale(1);
    visibility: visible;
  }    

  &.active {
    opacity: 0;
    transform: scale(0);
  }    

  &.normal {
    opacity: 1;
    transform: scale(1);
  }    

}

.cursor-follower {
  position: fixed;
  
  border:1px solid rgba(100,100,100,0.35);
  // background: rgba(100,100,100,0.25);
  width: 30px;
  height: 30px;
  border-radius: 100%;
  z-index: 1;
  transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
  0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
  user-select: none;
  pointer-events: none;
  z-index: 10000;


  left: 50%;
  top: 50%;
  transform: scale(0) translate(2px, 2px);
  opacity:0;
  visibility: hidden;


  &.visible {
    opacity: 1;
    transform: scale(1) translate(2px, 2px);
    visibility: visible;
    transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
  }    

  &.active {
    opacity: 1;
    border:1px solid rgba(255,255,255,1);
    transform: scale(3);
  }    

  &.normal {
    opacity: 1;
    transform: scale(1);
    border:1px solid rgba(255,255,255,0.2);
  }   

}

  .letter, .letter2 {
    display: inline-block;
    position: relative;
  }

  #wrapper {
    height: 100vh;
  }
  .counter {
    display: block;
    position: relative;
  }

.scale2-enter-active,
.scale2-leave-active {
  position: absolute;
  // background: red;
  transition: all 0.5s ease;
 transform-origin: 0 0; transform: scale(1);  transition: all 0.5s ease;

}


.scale2-enter-from,
.scale2-leave-to {
  opacity: 0;
  // position: absolute;
  // transform: translateZ(-30px);  
  transition: all 0.5s ease;
  transform: scale(0.9);
}

.scale3-enter-active,
.scale3-leave-active {
  position: absolute;
  // background: red;
  transition: all 0.5s ease;
 transform-origin: center center; transform: scale(1);  transition: all 0.5s ease;

}


.scale3-enter-from,
.scale3-leave-to {
  opacity: 0;
  // position: absolute;
  // transform: translateZ(-30px);  
  transition: all 0.5s ease;
  transform: scale(0.0);
}

.scale-enter-active,
.scale-leave-active {

  // background: red;
  transition: all 0.5s ease;
  > section { transform-origin: 0 0; transform: translateZ(0px);  transition: all 0.5s ease; }

}


.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  section { transform: translateZ(-30px);  transition: all 0.5s ease; }
  // transform: scale(0.9);
}
