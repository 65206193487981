:root {
	////////////////////////////////////////
	// 1.COLORS VARIABLES 
	///////////////////////////////////////
	--transparent:   rgba(0,0,0,0);

	// Main and secondary colors
	--main:      		#00ff88; 
	--second:    		#0088ff;

	// Main tons
	--light-main:  	rgba(--main, 	0.1);
	--light-second:  rgba(--second, 0.1);

	// Black and white colors
	--black:      		#000000;
	--dark:      		#242424; 
	--darkgrey:      #7A7A7A; 
	--grey:      		#F7F7F7; 
	--light:     		#f9fbfc; 
	--white:     		#ffffff;
	// Tones
	--light-red: 	#FFBBC8;

	// For brands
	--youtube:				#FC0D1B;
	--telegram:			linear-gradient(0deg, #1D93D2 0%, #38B0E3 100%), #000000;
	--twitter:				#1D9BF0;
	--instagram:			#e95950;
	--reddit:				#FF4500;

	////////////////////////\////////////////
	// 2.SIZE VARIABLES //
	///////////////////////////////////////
	--extra:   		5.00rem;
	--big:   			2.50rem; 
	--semi:    		1.50rem;
	--medium:  		1.25rem;
	--regular: 		1.00rem;
	--small:   		0.75rem;
	--thin:    		0.50rem;
	--extra-thin: 	0.25rem;

	////////////////////////////////////////
	// 3. TEXT VARIABLES
	///////////////////////////////////////
	--font: 'Avenir Next';

	////////////////////////////////////////
	// 4. BREAKPOINTS
	///////////////////////////////////////
	--super-ultra-tiny-flip-phone-max: 240px;
	--flip-phone-max: 									320px;
	--phone-portrait-max: 							321px;
	--phone-landscape-max: 						640px;
	--tablet-portrait-min: 						641px;
	--tablet-portrait-max: 						768px;
	--tablet-landscape-min: 						769px;
	--tablet-landscape-max: 						1024px;
	--desktop-min: 										1025px;
	--desktop-max: 										1440px;
	--xl-desktop-min: 									1441px;
	--xl-desktop-max: 									1920px;
	--xxl-desktop-min: 								1921px;
}